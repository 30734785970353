import React, { useEffect, useContext } from 'react';
import Layout from '@/components/layout';
import Metadata from '@/components/Metadata/Metadata';
import FeatureTiles from '@/components/FeatureTiles/FeatureTiles';
import PageData from '../data/pages/loans/loan-repayment-calculator.json';
import FeaturesStaggeredSlider from '../components/FeaturesStaggeredSlider/FeaturesStaggeredSlider';

import heroImage from '../images/hero/pl-loan-repayment.webp';

import { PageContext } from '@/context/PageContext';
import { CONTENTFUL_COMPONENTS, PL_APPLY_CTA_TRACK_ID } from '@/utils/constants';
import HeroBanner from '@/components/lab-components/HeroBanner';
import { useSetState } from '@/utils/hooks';
import PageHeaderSection from '@/components/PageLayout/PageHeader';
import { AnalyticsContext } from 'latitude-analytics';
import Section from '@latitude/section';
import PersonalLoanCalculator from '@/components/PersonalLoanCalculator2/PersonalLoanCalculator';
import ProofPointCollection from '@/components/lab-components/ProofPointCollection';
import CardCollection from '@/components/lab-components/CardCollection';


const CalculatorsAndToolsPage = props => {
  /** Contentful Data */
  const contentfulPageData = useContext(PageContext);
  const state = contentfulPageData?.updatedBody || {};
  
  const CTA_BUTTON_LABEL = 'Get my rate estimate';
  const COLOUR_PALE_BLUE = '#E5F6FF';
  /** - END - */

  const [analytics] = useContext(AnalyticsContext);

  // adding pageData to analytics
  analytics.pageData = {
    page_pageTitle: 'loan-repayment-calculator',
    page_pageType: 'calculator-page',
    page_siteSection: 'loans',
    page_siteSubsection: 'loan-repayment-calculator',
    product_productid: ["PLAULF-WEB"]
  };

  return (
    <Layout location={props.location}>
      <main className="navigation-spacer">
        <Metadata
          title={PageData.metaTitle}
          description={PageData.metaDesc}
          canonical="/loan-repayment-calculator/"
        />
        {state?.heroBannerData?.[0] ? (
          <HeroBanner {...state?.heroBannerData[0]} />
        ) : (
          <PageHeaderSection
            pageImage={heroImage}
            title="Loan Repayment Calculators and Tools"
            subTitle="Some simple tools to help you kick your financial goals faster."
          />
        )}
          <Section
            id="repayment-calculator"
            heading=""
            css={`
              background-color: ${COLOUR_PALE_BLUE};
              padding-top: 32px;
              padding-bottom: 32px;
              && h2.text-center {
                text-align: left !important;
                font-size: 32px;
                line-height: 36px;
                margin-top: 0px;
                margin-bottom: 30px;
              }
            `}
          >
            <PersonalLoanCalculator
              loans1
              ctaButtonLabel={CTA_BUTTON_LABEL}
              applyCTATrackId={PL_APPLY_CTA_TRACK_ID}
            />
          </Section>
          <ProofPointCollection  
            id="calculators-and-tools"
            data={
              state?.proofPointCollectionData?.[0] || PageData?.contentful?.proofPointCollectionData?.[0]
            }
          />

      <CardCollection
            id="life-cant-wait"
            data={
              state?.cardCollectionData?.[0] || PageData?.contentful?.cardCollectionData?.[0] 
            }
          />
      </main>
    </Layout>
  );
};

export default CalculatorsAndToolsPage;
