import React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import { Typography } from '../Typography';
import SvgInline from '@/components/SvgInline/SvgInline';
import Icon from '@/components/Icon/Icon';
import { Link } from '@latitude/link';
import { BREAKPOINT, ICON_VARIANT, ICON_SIZE } from '@/utils/constants';
import './_proof_point.scss';

const ProofPoint = ({
  point,
  proofPointTitleSize,
  theme,
  numOfPoints = 3,
  category,
  action
}) => {
  const TITLE_OPTIONS_H6 = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (_node, children) => (
        <Typography.H6>{children}</Typography.H6>
      )
    }
  };

  const TITLE_OPTIONS_H5 = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (_node, children) => (
        <Typography.H5>{children}</Typography.H5>
      )
    }
  };
  return (
    <Link
      href={point?.proofPointUrl}
      style={{ cursor: point?.proofPointUrl ? 'pointer' : 'default' }}
      className={`proof-point proof-point--${theme?.toLowerCase()}`}
      trackEventData={{
        label: point?.name,
        location: point?.name,
        category: category,
        action: action
      }}
      css={`
        flex: 0 1 100%; /* Default: 1 card per row */
        max-width: 100%;
      
        @media (min-width: ${BREAKPOINT.MD}) {
          flex: 0 1 calc(100% / ${numOfPoints > 1 ? 2 : 1} - 30px); /* 2 cards if more than 1 */
          max-width: 540px;
        }
      
        @media (min-width: ${BREAKPOINT.LG}) {
          flex: 0 1 calc(100% / ${numOfPoints > 2 ? 3 : numOfPoints} - 30px); /* 3 cards if more than 2 */
          max-width: 540px;
        }
      
        @media (min-width: ${BREAKPOINT.XL}) {
          flex: 1 1 calc(100% / ${numOfPoints > 3 ? 4 : numOfPoints} - 30px); /* 4 cards if more than 3 */
          max-width: 540px;
        }
      `}
      target="_self"
    >
      <div className="proof-point-content">
        {point?.icon && (
          <SvgInline
            className={`proof-point__icon proof-point__icon`}
            name={point?.icon}
          />
        )}
        <div className="proof-point__title">
          {documentToReactComponents(
            point?.title,
            proofPointTitleSize === 'Heading 6'
              ? TITLE_OPTIONS_H6
              : TITLE_OPTIONS_H5
          )}
        </div>
        {point?.proofPointUrl && (
          <Icon
            variant={ICON_VARIANT.ARROW_CHEVRON_RIGHT}
            size={ICON_SIZE.SM}
            color="#0061EE"
            className="proof-point__arrow"
          />
        )}
      </div>
    </Link>
  );
};
export default ProofPoint;
