import React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import { Typography } from '../Typography';
import Section from '@latitude/section';
import ProofPoint from '../ProofPoint';
import './_proof_point_collection.scss';

const ProofPointCollection = props => {
  const {
    title,
    proofPoints,
    proofPointTitleSize = 'Heading 6',
    proofPointIconSize = 40,
    theme = 'Grey'
  } = props.data;

  const TITLE_OPTIONS = {
    renderNode: {
      [BLOCKS.HEADING_3]: (_node, children) => (
        <Typography.H3>{children}</Typography.H3>
      )
    }
  };

  return (
    <Section
      id={props.id || ''}
      className={`proof-point-collection proof-point-collection--${theme?.toLowerCase()}`}
    >
      {title && (
        <div className="proof-point-collection__heading">
          {documentToReactComponents(title, TITLE_OPTIONS)}
        </div>
      )}
      <div className="proof-point-collection__content" css={`justify-content: ${proofPoints?.length <= 1 ? 'start' : 'center'}`}>
        {proofPoints?.map((point, index) => (
          <ProofPoint
            key={index}
            proofPointTitleSize={proofPointTitleSize}
            proofPointIconSize={proofPointIconSize}
            point={point}
            theme={theme}
            numOfPoints={proofPoints?.length}
            category={props?.category || 'text-link'}
            action={props?.action || 'internal-link'}

          />
        ))}
      </div>
    </Section>
  );
};

export default ProofPointCollection;
